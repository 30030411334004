<template>
  <div
    class="flex items-center text-xsm"
    :class="{
      'gap-2': !giant,
      'gap-4': giant,
    }"
  >
    <div
      class="border-2 border-neutral-white rounded-full bg-cover bg-center flex items-center justify-center bg-neutral-100 text-typography-secondary overflow-hidden"
      :class="{
        'h-8 w-8': !giant,
        'h-10 w-10': giant,
      }"
    >
      <img v-if="image" :src="image" class="h-full w-full object-cover" />
      <span v-show="!image" class="uppercase">{{ initialsComputed }}</span>
    </div>

    <span
      class="text-typography-primary"
      :class="{
        'text-[32px] font-faktum': giant,
      }"
      >{{ name }}</span
    >
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  giant: {
    default: false,
    required: false,
    type: Boolean,
  },
  image: {
    default: '',
    required: false,
    type: String,
  },
  initials: {
    default: '',
    required: false,
    type: String,
  },
  name: {
    default: '',
    required: false,
    type: String,
  },
});

const initialsComputed = computed(() => {
  if (props.initials) return props.initials;

  const names = props.name.split(' ');

  if (names.length === 1) {
    return names[0].charAt(0);
  }

  return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
});
</script>
